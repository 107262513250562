import React from "react"
import { Link } from 'gatsby'

export default () => {
    return (
        <div className="layout title__72 blue">
            <div className="layout__container">
                <div className="layout__wide footer">
                    <div className="grid grid__4 grid__gap--48">
                        <div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/background-checks/">Background Checks</Link></li>
                                <li><Link to="/about/">About</Link></li>
                                <li><Link to="/blog/">Blog</Link></li>
                                <li><Link to="/contact/">Contact</Link></li>
                                <li><Link to="/privacy/">Privacy</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3>"Trust but verify"</h3>
                            <p>Workplace integrity is at the heart of what we strive to achieve and through our background checks we encourage the famous dictum <em>"Trust but verify"</em>.</p>
                        </div>
                        <div className="right">
                            <h3>New Zealand</h3>
                            <ul>
                                <li><a href='mailto:enquiries@staffchecks.com' title="enquiries@staffchecks.com">enquiries@staffchecks.com</a></li>
                                <li><p>PO Box 82<br/>Clevedon 2248<br />Auckland, New Zealand</p></li>
                            </ul>
                        </div>
                        <div className="right">
                            <h3>International</h3>
                            <ul>
                                <li><a href='mailto:enquiries@staffchecks.com' title="enquiries@staffchecks.com">enquiries@staffchecks.com</a></li>
                                <li><p>PO Box 82<br/>Clevedon 2248<br />Auckland, New Zealand</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


