import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"

export default () => (
    <StaticQuery
        query={graphql`
        query {
            logo: file(relativePath: { eq: "staffchecks.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `}
    render = { data => (
        <div className="">
        <div className="layout header nav__sticky">
            <div className="layout__container">
                <div className="layout__nav">
                    <div className="grid grid__nav align__items">
                        <div>
                            <Link to="/"><Img fluid={data.logo.childImageSharp.fluid} backgroundColor={`#fff`} alt="StaffChecks" /></Link>
                        </div>
                        <nav className="nav nav__right">
                            <ul>
                                <li><a href="https://portal.staffchecks.com" rel="nofollow noopener" className="login">Login &rsaquo;</a></li>
                            </ul>
                            <ul className="nav__mobile__off sf__menu">
                                <li><Link to="/" activeClassName="active">Home</Link></li>
                                <li><Link to="/background-checks/" activeClassName="active" partiallyActive={true}>Background Checks</Link></li>
                                <li><Link to="/about/" activeClassName="active" partiallyActive={true}>About</Link></li>
                                <li><Link to="/contact/" activeClassName="active">Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )}
    />
)
