import React from "react"

export default () => {
    return (
        <div className="layout">
            <div className="layout__container">
                <div className="layout__wide copyright">
                    <p>&copy;2020</p>
                </div>
            </div>
        </div>
    )
}
